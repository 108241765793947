((docs)=>{

    const myDoc = docs;

    const header = myDoc.getElementById('siteHeader');
    const sticky = header.offsetTop;
    const onScroll = () => {
      if (window.scrollY > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky")
      }
    }
    window.addEventListener('scroll', onScroll);

    
      
    const menuDropdown = (nai) => {
      const menu = myDoc.querySelector(nai);
      const offcanv = myDoc.querySelector('.nav-min');
      let mediaQ = window.matchMedia(`(max-width: 991px)`);
      //let menu = myDoc.querySelector('.nav-min-menu');
      let dropMenuItems = menu.querySelectorAll('.haveChild');
      if(mediaQ.matches) {            
          let mobMnuHdr = myDoc.querySelector('.nav-min-header');
          let mobMnuCloser = myDoc.querySelector('.nav-min-close');
          Object.keys(dropMenuItems).forEach((key) => {
              let item = dropMenuItems[key].querySelector('a');
              //console.log(item);
              let subMenu = dropMenuItems[key].querySelector('.dropdown-menu');
              let chieldCloseBtn = subMenu.querySelector('.nav-min-menu-children-closer');
              item.addEventListener('click', (e) => {                
                  e.preventDefault();
                  subMenu.classList.add('show');
                  mobMnuHdr.classList.add('position-fixed');
              });
              chieldCloseBtn.addEventListener('click', (e) => {
                  e.preventDefault();
                  subMenu.classList.remove('show');
                  setTimeout(() => {
                      mobMnuHdr.classList.remove('position-fixed');
                  }, 10);
              });
              mobMnuCloser.addEventListener('click', (e) => {
                  subMenu.classList.remove('show');
                  setTimeout(() => {
                      mobMnuHdr.classList.remove('position-fixed');
                  }, 10);
              });
          });


          offcanv.addEventListener('hide.bs.offcanvas', event => {
            mobMnuHdr.classList.remove('position-fixed');
          });


          
      }else{
        let dropMenu = menu.querySelectorAll('.dropdown');
        let overlayEle = myDoc.createElement('div');
        overlayEle.className = 'overlay';

        myDoc.addEventListener('click', (e) => {
          let dropMenuTglr = e.target.closest('.dropdown-menu');          
            if(!dropMenuTglr && overlayEle) {            
              overlayEle.remove();
            }
        });

        dropMenu.forEach((item) => {
          item.addEventListener('show.bs.dropdown', event => {
              setTimeout(() => {   
                let dropOverBg = myDoc.querySelector('.overlay');
                ! dropOverBg? myDoc.body.appendChild(overlayEle):'';
              }, 6);
          });          
        });
      }
      
    };

    menuDropdown('.nav-min-menu');
    window.addEventListener('resize', (e)=>{
        //console.log('resize');
        menuDropdown('.nav-min-menu');
    });

    let filter = myDoc.querySelector('.filter-btn');
    if(filter) {
      let eachClick = true;
      filter.addEventListener('click', (e) => {  
        let filterPanl = myDoc.querySelector('.filter');
        let galView = myDoc.querySelector('.gallery-view');
        if(eachClick) {
          eachClick=false;
          filterPanl.classList.add('show');
          galView.classList.add('col-xl-10');
        } else {
          eachClick=true;
          filterPanl.classList.remove('show');
          galView.classList.remove('col-xl-10');
        }
      });

      let filterClose = myDoc.querySelector('.filter-close');
      filterClose.addEventListener('click', (e) => {
        let filterPanl = myDoc.querySelector('.filter');
        let galView = myDoc.querySelector('.gallery-view');
        filterPanl.classList.remove('show');
        galView.classList.remove('col-xl-10');
        eachClick=true;
      });
    }

    //search    
    const searchOpen = () => {  
      const search = myDoc.querySelector('.search-header');  
      let formInput = search.querySelector('.form-control');  
      
      // Create overlay div  
      let divEle = myDoc.createElement('div');  
      divEle.className = 'search-overlay';  
      myDoc.body.appendChild(divEle);
      search.classList.add('active');  
      formInput.focus(); 
    };  

    const searchBtn = myDoc.querySelectorAll('.iconSearch');  
    Object.keys(searchBtn).forEach(function(key) {  
        searchBtn[key].addEventListener('click', function(e) {  
            e.preventDefault();  
            searchOpen();  
            return false;  
        });  
    });  

    myDoc.addEventListener('click', function(event) {  
        let searchManHdr = myDoc.querySelector('.search-header');  
        let searchBtn = myDoc.querySelector('.iconSearch'); 
        
        if (searchManHdr && !searchManHdr.contains(event.target) && (searchBtn && !searchBtn.contains(event.target))) {  
            let divEle = myDoc.querySelector('.search-overlay');  
            if (divEle) {  
                myDoc.body.removeChild(divEle); // Remove overlay  
            }  
            searchManHdr.classList.remove('active'); // Deactivate search form  
        }  
    });


      //search By KeyUp
    let searchByKeyUp = myDoc.querySelectorAll('.searchByKeyUp');

    //scalable search function
    function filterFunction(element, input) {
      var  filter, ul, li, a, i;      
      filter = input.value.toUpperCase();
      //div = document.getElementById("filter_dorpdown_list");
      a = element.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
          txtValue = a[i].textContent || a[i].innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
              a[i].style.display = "";
          } else {
              a[i].style.display = "none";
          }
      }
    }

    if(searchByKeyUp){
      Object.keys(searchByKeyUp).forEach(ele => {
        let input = searchByKeyUp[ele].querySelector(".form-control");
        let itemWrap = searchByKeyUp[ele].querySelector(".model-search");
        //console.log(input, itemWrap) ;    
        if(input){   
          input.addEventListener('keyup', (e) => {
            filterFunction(itemWrap, e.target);
          });
        }
      })
    }

    //favourite
    myDoc.addEventListener('click', function(event) {    
        //console.log(event.target);  
        if (event.target.classList.contains('favourites-icon')) {
            event.target.classList.toggle('active');            
            if (event.target.classList.contains('active')) {
                event.target.parentElement.querySelector('.favourites-message').textContent = 'Added to favourites!';
            } else {
                event.target.parentElement.querySelector('.favourites-message').textContent = 'Removed from Favourites!';
            }            
            event.target.parentElement.querySelector('.favourites-message').classList.add('active');
        }
    });
    
    myDoc.addEventListener('click', function(event) {
        if (event.target.classList.contains('favourites-message')) {
            event.target.classList.remove('active');
        }
    });

    myDoc.addEventListener('click', function(event) {
        if (!event.target.closest('.favourites')) {
            myDoc.querySelectorAll('.favourites-message').forEach(function(message) {
                message.classList.remove('active');
            });
        }
    });


    const formGroup = myDoc.querySelectorAll('.form-group');
    if(formGroup) {
      Object.keys(formGroup).forEach(i =>{
        let lable = formGroup[i].querySelector('label');
        let input = formGroup[i].querySelector('.form-control');
        if(lable) {
          input.addEventListener('blur', (e)=>{
            if(e.target.value!==''){
              e.target.classList.add('hasVal');
            }else{
              e.target.classList.remove('hasVal');
            }
          })
        }
      })
    }

    //input select dropdown selectted image needs to show on image tag
    let selectScContainer = myDoc.querySelectorAll('.select-escort');
    if(selectScContainer) {
      Object.keys(selectScContainer).forEach(i=>{
          let image = selectScContainer[i].querySelector('.booking-img');
          let input = selectScContainer[i].querySelector('.form-control');
          if(image) {
            let prevSec = image.src;
            input.addEventListener('change', (e) => {
              let img = e.target.options[e.target.selectedIndex].getAttribute('data-value');
              if(img) {
                e.target.closest('.select-escort').querySelector('.booking-img').src = img;
              }else{
                e.target.closest('.select-escort').querySelector('.booking-img').src = prevSec;
                //console.log(prevSec);
              }
              //console.log(img);
            })
          }
      })
    };

    //custom upload
    const inputFilewrap = myDoc.querySelectorAll('.inputFile');
    if(inputFilewrap) {
      Object.keys(inputFilewrap).forEach(function(key) {
          inputFilewrap[key].querySelector('[type="file"]').addEventListener('change', function() {
              if (inputFilewrap[key].querySelector('[type="file"]').files.length > 0) {
                  inputFilewrap[key].querySelector('.form-control').innerText = inputFilewrap[key].querySelector('[type="file"]').files[0].name;
              }
          });
      });
    }


//for debu     
// const multiSelect = [];
// const addRemoveActiveOnclick = (ele) => {
//     if (!ele) return;

//     let targetEle = document.querySelector(ele);
//     if (!targetEle) return;

//     let actinArray = targetEle.querySelectorAll('li');

    
//     const attachRemoveEvents = (dropdown) => {
//         dropdown.querySelectorAll('.remove-item').forEach(removeBtn => {
//             removeBtn.addEventListener('click', (e) => {
//                 e.stopPropagation();
//                 let itemToRemove = e.target.parentElement.dataset.title;

//                 // Remove from multiSelect array
//                 let index = multiSelect.indexOf(itemToRemove);
//                 if (index !== -1) {
//                     multiSelect.splice(index, 1);
//                 }

//                 // Remove active class from corresponding dropdown item
//                 actinArray.forEach(item => {
//                     if (item.dataset.title === itemToRemove) {
//                         item.classList.remove('active');
//                     }
//                 });

//                 updateMultiSelectList(dropdown);
//                 console.log(multiSelect);
//             });
//         });
//     };

//     // Function to update the selected items display
//     let updateMultiSelectList = (dropdown) => {
//         let multiSelectList = multiSelect
//             .map(item => `<b data-title="${item}">${item} <i class="remove-item">X</i></b>`)
//             .join('');
//         dropdown.querySelector('button .selected-ele').innerHTML = multiSelectList;

//         attachRemoveEvents(dropdown);
//         console.log(multiSelect);
//     };

//     actinArray.forEach(item => {
//       item.addEventListener('click', (e) => {
//           e.stopPropagation();
//           let targetItem = e.target;
//           targetItem.classList.toggle('active');

//           let itemTitle = targetItem.dataset.title;

//           if (targetItem.classList.contains('active')) {
//               if (!multiSelect.includes(itemTitle)) {
//                   multiSelect.push(itemTitle);
//               }
//           } else {
//               let index = multiSelect.indexOf(itemTitle);
//               if (index !== -1) {
//                   multiSelect.splice(index, 1);
//               }
//           }

//           updateMultiSelectList(targetEle);
//           console.log(multiSelect);
//       });

//     });
// };

// // Initialize
// addRemoveActiveOnclick('.dropdown-miltiselect');
    
})(document);